<template>
  <div>
    <b-card title="Filter">
      <b-row>
        <b-col
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Type</label>
          <v-select
            v-model="typeFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="typeOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Search Key</label>
          <b-form-input
            id="searchKeyInput"
            v-model="searchKey"
            placeholder="Key Word Here..."
          />
        </b-col>
        <b-col
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-button
            class="mt-2"
            variant="primary"
            @click="doSearch"
          >
            Search
          </b-button>
          <!-- <b-button
            class="mt-2 ml-2"
            variant="info"
            @click="doUpload"
          >
            Upload
          </b-button> -->
        </b-col>
      </b-row>
    </b-card>

    <b-card no-body>
      <b-table
        class="position-relative"
        responsive
        :fields="sanctionsTableFields"
        :items="records"
        primary-key="uid"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >
        <template #cell(show_details)="row">
          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
          <b-form-checkbox
            v-model="row.detailsShowing"
            @change="row.toggleDetails"
          >
            {{ row.detailsShowing ? 'Hide' : 'Show' }}
          </b-form-checkbox>
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <b-card title="Details">
            <b-row>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>UID : </strong>{{ row.item.uid }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>First Name : </strong>{{ row.item.first_name }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>Last Name : </strong>{{ row.item.last_name }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>Title : </strong>{{ row.item.title }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>Type : </strong>{{ row.item.sdn_type }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>Remarks : </strong>{{ row.item.remarks }}
              </b-col>
            </b-row>
          </b-card>

          <b-card
            v-if="row.item.program_list"
            title="Propram(s)"
            class="mt-2"
          >
            <b-row>
              <b-col
                v-for="item in row.item.program_list"
                :key="item.index"
                md="4"
                class="mb-1"
              >
                {{ item }}
              </b-col>
            </b-row>
          </b-card>

          <b-card
            v-if="row.item.id_list"
            title="ID(s)"
            class="mt-2"
          >
            <b-table
              class="position-relative"
              responsive
              :fields="idsTableFields"
              :items="row.item.id_list"
              primary-key="uid"
              show-empty
              striped
              :borderless="false"
              empty-text="No matching records found"
            />
          </b-card>

          <b-card
            v-if="row.item.aka_list"
            title="A.K.A."
            class="mt-2"
          >
            <b-table
              class="position-relative"
              responsive
              :fields="akaTableFields"
              :items="row.item.aka_list"
              primary-key="uid"
              show-empty
              striped
              :borderless="false"
              empty-text="No matching records found"
            />
          </b-card>

          <b-card
            v-if="row.item.address_list"
            title="Address(s)"
            class="mt-2"
          >
            <b-table
              class="position-relative"
              responsive
              :fields="addressTableFields"
              :items="row.item.address_list"
              primary-key="uid"
              show-empty
              striped
              :borderless="false"
              empty-text="No matching records found"
            />
          </b-card>

          <b-card
            v-if="row.item.nationality_list"
            title="Nationality(s)"
            class="mt-2"
          >
            <b-table
              class="position-relative"
              responsive
              :fields="nationalityTableFields"
              :items="row.item.nationality_list"
              primary-key="uid"
              show-empty
              striped
              :borderless="false"
              empty-text="No matching records found"
            />
          </b-card>

          <b-card
            v-if="row.item.citizenship_list"
            title="Citizenship(s)"
            class="mt-2"
          >
            <b-table
              class="position-relative"
              responsive
              :fields="citizenshipTableFields"
              :items="row.item.citizenship_list"
              primary-key="uid"
              show-empty
              striped
              :borderless="false"
              empty-text="No matching records found"
            />
          </b-card>

          <b-card
            v-if="row.item.date_of_birth_list"
            title="DateOfBirth"
            class="mt-2"
          >
            <b-table
              class="position-relative"
              responsive
              :fields="dateBirthTableFields"
              :items="row.item.date_of_birth_list"
              primary-key="uid"
              show-empty
              striped
              :borderless="false"
              empty-text="No matching records found"
            />
          </b-card>

          <b-card
            v-if="row.item.place_of_birth_list"
            title="PlaceOfBirth"
            class="mt-2"
          >
            <b-table
              class="position-relative"
              responsive
              :fields="placeBirthTableFields"
              :items="row.item.place_of_birth_list"
              primary-key="uid"
              show-empty
              striped
              :borderless="false"
              empty-text="No matching records found"
            />
          </b-card>

          <b-card
            v-if="row.item.vessel_info"
            title="VesselInfo"
            class="mt-2"
          >
            <b-row>
              <b-col
                v-for="(value, key) in row.item.vessel_info"
                :key="key"
                md="4"
                class="mb-1"
              >
                <strong>{{ key }} : </strong>{{ value }}
              </b-col>
            </b-row>
          </b-card>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput, BButton, BCard, BRow, BCol, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { showToast } from '@/libs/tool'
import useAntiFraudJs from './anti-fraud'

const {
  fetchSanctions,
} = useAntiFraudJs()

export default {
  components: {
    BFormInput,
    BButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      searchKey: '',
      records: [],
      sanctionsTableFields: [
        'show_details',
        { key: 'last_name', label: 'Name' },
        { key: 'sdn_type', label: 'Type' },
        { key: 'program_list', label: 'Program(s)' },
      ],
      typeFilter: '1',
      typeOptions: [
        { label: 'SDN', value: '1' },
        { label: 'Non-SDN', value: '2' },
      ],
      idsTableFields: [
        'uid',
        'id_type',
        'id_number',
        'id_country',
        'issue_date',
        'expiration_date',
      ],
      akaTableFields: [
        'uid',
        'type',
        'category',
        'last_name',
        'first_name',
      ],
      addressTableFields: [
        'uid',
        'address1',
        'address2',
        'address3',
        'city',
        'state_or_province',
        'postal_code',
        'country',
      ],
      nationalityTableFields: [
        'uid',
        'country',
        'main_entry',
      ],
      citizenshipTableFields: [
        'uid',
        'country',
        'main_entry',
      ],
      dateBirthTableFields: [
        'uid',
        'date_of_birth',
        'main_entry',
      ],
      placeBirthTableFields: [
        'uid',
        'place_of_birth',
        'main_entry',
      ],
    }
  },
  methods: {
    doSearch() {
      this.records = []
      const params = {
        type: this.typeFilter,
        searchKey: this.searchKey,
      }
      fetchSanctions(
        params,
        records => {
          this.records = records
        },
        fail => {
          showToast(this, 'Warning', `Fetch records with ${fail}`)
        },
      )
    },
    doUpload() {
      this.records = []
      const params = {
        mode: this.typeFilter,
        provider: this.providerFilter,
        searchKey: this.searchKey,
      }
      fetchSanctions(
        params,
        records => {
          this.records = records
        },
        fail => {
          showToast(this, 'Warning', `Fetch records with ${fail}`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
